<template>
  <div class="box">
    <div class="scanLogin_title">
        <!-- <img src="@/assets/images/scanLogin/back.png" alt=""> -->
        <p>扫码登录</p>
        </div>
    <div class="scanLogin_card">
        <div class="scanLogin_box">
            <img src="@/assets/images/scanLogin/computer.png" alt="">
            <p>通信网服中心登录确认</p>
        </div>
        <div class="scanLogin_btn">
            <button @click="loginStatus">确认登录</button>
            <p>取消登录</p>
        </div>
    </div>
  </div>
</template>

<script>
var url = window.location.href; //获取当前url
console.log(url,'url');
// var dz_url = url.split("&")[0]; //获取&之前的字符串
// console.log(dz_url,'&之前的字符串');
// var cs = dz_url.split("?")[1]; //获取?之后的参数字符串
var cs = url.split("?")[1]; //获取?之后的参数字符串
console.log(cs,'?之后参数');
var cs_arr = cs.split("&"); //参数字符串分割为数组
console.log(cs_arr,'字符串分割为数组');
var cs={};
for(var i =0; i<cs_arr.length;i++){
    cs[cs_arr[i].split("=")[0]] = cs_arr[i].split("=")[1];
}
console.log(cs,'cs');
console.log(cs.number);
import distubritionTwoApi from '@/untils/distubritionTwo.js'
export default {
    data(){
        return{
        }
    },
    methods:{
    //APP扫码登录
    loginStatus(){
      let data = {
        token:cs.token,
        mobile:cs.number
      }
      distubritionTwoApi.loginStatus({data}).then((res)=>{
        if(res.data.code == 200){
            this.$toast('登录成功');
            let u = navigator.userAgent
            let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
            let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if (isAndroid) {
                window.android.GoBack(); // 调用原生app的函数--webClose是安卓提供的方法
            }
            if (isIOS) {
                window.webkit.messageHandlers.GoBack.postMessage('');//--webClose是ios提供的方法
            }
        }
      })
    },
    }

}
</script>

<style lang="less" scoped>
    .box{
        min-height: 100vh;
        overflow: hidden;
        
        .scanLogin_title {
            width: 100%;
            height: 44px;
            line-height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            color: #333;
            font-weight: 550;
            background-color: #fff;

        }
        .scanLogin_card{
                width: 340px;
                height: 658px;
                margin: 17px auto;
                background-color: #fff;
                text-align: center;
                overflow: hidden;
                .scanLogin_box{
                    margin-top: 100px;
                    p{
                        font-size: 14px;
                        font-weight: 500;
                        margin-top: 9px;
                        color: #333333;
                    }
                }
                .scanLogin_btn{
                    margin-top: 233px;
                    button{
                        width: 270px;
                        height: 43px;
                        color: #fff;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 13px;
                        border-radius: 21px;
                        background: #1E6FFF;
                    }
                    p{
                        margin-top: 25px;
                        font-size: 14px;
                        font-weight: 500;
                        color: #395B98;
                    }
                }
            }
    }
</style>